import React from "react";
import { useDispatch } from "react-redux";
import penguinLogin from "../../assets/Simplified Logo.png";

import { updatePathName } from "../../redux/Pengwin";
import styles from "./Header.module.scss";

export function Header() {
  const dispatch = useDispatch();

  return (
    <div className={styles.app}>
      <header className={styles.header}>
        <div>
          <img
            src={penguinLogin}
            alt="penguinLogo"
            className={styles.logo}
            onClick={() => {
              dispatch(updatePathName("logo"));
            }}
          />
        </div>
        <div className={styles.headerText}>
          <div
            className={styles.button}
            onClick={() => {
              dispatch(updatePathName("home"));
            }}
          >
            <h2>Home</h2>
          </div>
          <div
            className={styles.button}
            onClick={() => {
              dispatch(updatePathName("company"));
            }}
          >
            <h2>Company</h2>
          </div>
          <div
            className={styles.button}
            onClick={() => {
              dispatch(updatePathName("services"));
            }}
          >
            <h2>Services</h2>
          </div>
        </div>
        <div />
      </header>
    </div>
  );
}
