import { useSelectPathName } from "../../redux/Pengwin/selectors";
import { Company } from "../Company/Company";
import { Footer } from "../Footer/Footer";
import { Header } from "../Header/Header";
import { Home } from "../Home/Home";
import { Services } from "../Services/Services";
import styles from "./Dashboard.module.scss";

export function Dashboard() {
  const pathName = useSelectPathName();

  return (
    <div className={styles.container}>
      <Header />
      {(pathName === "home" || pathName === "logo" || pathName === '') && <Home />}
      {pathName === "company" && <Company />}
      {pathName === "services" && <Services />}
      <Footer />
    </div>
  );
}
